import { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Timestamp, doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { fireDB } from '../../../Firebase/FirebaseConfigData';
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

// Ensure you are correctly getting the environment variable


function CreateBlog() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState({
    title: '',
    category: '',
    content: '',
    slug: '',
    time: Timestamp.now(),
  });
 
  const [isLoading, setIsLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false); // State to toggle preview visibility

  // Check if the slug already exists
  const checkSlugExists = async (slug: string) => {
    const postCollectionRef = collection(fireDB, "blogPost");
    const q = query(postCollectionRef, where("slug", "==", slug));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  // Add the blog post to Firestore
  const addPost = async () => {
    if (!blogs.title || !blogs.category || !blogs.content || !blogs.slug) {
      toast.error('Please fill all fields');
      return;
    }

    const slugExists = await checkSlugExists(blogs.slug);
    if (slugExists) {
      toast.error('This slug already exists. Please choose a different one.');
      return;
    }

    setIsLoading(true);
    await createPost();
  };

  // Create the post document in Firestore
  const createPost = async () => {
    try {
      const postRef = doc(fireDB, `blogPost/${blogs.slug}`);
      await setDoc(postRef, {
        ...blogs,
        time: Timestamp.now(),
        date: new Date().toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
      });

      // Display a success toast when the blog is successfully created
      alert('Blog post created successfully!');

      // Redirect to the Create Subheading page
      navigate(`/create-subheading/${blogs.slug}`);
    } catch (error) {
      const e = error as Error;
      console.error(e.message);
      toast.error('Error creating the blog post');
    } finally {
      setIsLoading(false);
    }
  };

  // To display content preview
  const createMarkup = (content: string) => {
    return { __html: content };
  };

  return (
    <div className='container mx-auto max-w-6xl py-6'>
      <div className="p-6 bg-white shadow-xl rounded-lg flex flex-col lg:flex-row gap-6">
        
        {/* Left Side - Instructions and Buttons */}
        <div className="w-full lg:w-1/3">
          <div className="mb-4 flex justify-between items-center">
            <div className="flex gap-4 items-center">
              <Link to='/dashboard'>
                <BsFillArrowLeftCircleFill size={25} className="text-gray-600 hover:text-gray-800 transition duration-300" />
              </Link>
              <h4 className='text-3xl font-semibold text-gray-800'>Create Blog</h4>
            </div>
          </div>

          <div className="mb-5">
            <label htmlFor="title" className="block text-lg font-medium text-gray-700">Enter Your Title</label>
            <input
              className="w-full p-3 mt-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Title"
              name="title"
              onChange={(e) => setBlogs({ ...blogs, title: e.target.value })}
              value={blogs.title}
            />
          </div>

          {/* Category Input */}
          <div className="mb-5">
            <label htmlFor="category" className="block text-lg font-medium text-gray-700">Enter Your Category</label>
            <input
              className="w-full p-3 mt-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Category"
              name="category"
              onChange={(e) => setBlogs({ ...blogs, category: e.target.value })}
              value={blogs.category}
            />
          </div>

          {/* Slug Input */}
          <div className="mb-5">
            <label htmlFor="slug" className="block text-lg font-medium text-gray-700">Enter Your Unique ID (Slug)</label>
            <input
              className="w-full p-3 mt-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Slug"
              name="slug"
              onChange={(e) => setBlogs({ ...blogs, slug: e.target.value })}
              value={blogs.slug}
            />
          </div>

          {/* Instructions */}
          <div className="mt-4">
            <p className="text-sm text-red-600 font-bold mb-2">**Once you submit the blog, you will be redirected to a page to create subheadings for your blog post.**</p>
          </div>

          {/* Preview Button */}
          <div className="mt-4">
            <button
              className="w-full p-3 bg-blue-600 text-white text-lg font-semibold rounded-lg hover:bg-blue-700 transition duration-300"
              onClick={() => setShowPreview(!showPreview)} // Toggle preview visibility
            >
              {showPreview ? "Hide Preview" : "Show Preview"}
            </button>
          </div>
        </div>

        {/* Right Side - Content Editor and Submit */}
        <div className="w-full lg:w-2/3">
          <div className="mb-5">
            <label htmlFor="content" className="block text-lg font-medium text-gray-700">Enter Your Content</label>
            <Editor
             apiKey='gcxpuajmpzj70mtimd6kadj8x9mqypbhuptctnqgarsmkk58'
              onEditorChange={(newValue) => setBlogs({ ...blogs, content: newValue })}
              init={{
                height: 500,
                menubar: false,
                plugins: 'lists link image preview',
                toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | preview',
              }}
            />
          </div>

          {/* Submit Blog Button */}
          <button
            className="w-full p-3 bg-green-600 text-white text-lg font-semibold rounded-lg hover:bg-green-700 transition duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={addPost}
            disabled={isLoading}
          >
            {isLoading ? 'Creating...' : 'Submit Heading and Go to SubHeading'}
          </button>
        </div>
      </div>

      {/* Preview Section */}
      {showPreview && (
        <div className="mt-10">
          <h2 className="text-xl font-semibold text-gray-800">Content Preview</h2>
          <div className="mt-4 p-4 bg-gray-100 rounded-lg" dangerouslySetInnerHTML={createMarkup(blogs.content)} />
        </div>
      )}

      {/* Toast Container for notifications */}
      <ToastContainer />
    </div>
  );
}

export default CreateBlog;
