// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyCAS4Ji2iADfHAT9G65c0-jhoiQkzdTDgU",
//   authDomain: "forumde-blog.firebaseapp.com",
//   projectId: "forumde-blog",
//   storageBucket: "forumde-blog.appspot.com",
//   messagingSenderId: "136510906479",
//   appId: "1:136510906479:web:b256c4af16d74fdaa82d9a",
//   measurementId: "G-HBLCNDMZ56"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBszG_e5BI-E2An3Dtjwd8l6gR-EyRBBBk",
  authDomain: "forumde-16307.firebaseapp.com",
  projectId: "forumde-16307",
  storageBucket: "forumde-16307.firebasestorage.app",
  messagingSenderId: "839676330554",
  appId: "1:839676330554:web:4bf892d92169d2b8e36b03",
  measurementId: "G-G8Y2NH1E4Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const fireDB = getFirestore(app);
const auth = getAuth(app)
const storage = getStorage(app);
export { fireDB, auth, storage };