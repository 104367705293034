// App.tsx
import { Route, Routes } from "react-router-dom";
import Navbar from "./MainComponets/Header/pages";
import Testimonials from "./FrontEnd/Components/Testrimonials/page";
import Home from "./MainComponets/Home/home";
import { Footer } from "./MainComponets/Footer/page";
import Aboutus from "./FrontEnd/Components/AboutUS/Aboutus";
import PrivacyPolicy from "./FrontEnd/Additional/Private_Policy/page";
import TermsAndConditions from "./FrontEnd/Additional/TermsandCondition/page";
import Contactpage from "./FrontEnd/Additional/Contact_Us/contactpage";
import Page from "./FrontEnd/Additional/Underconstruction/page";
import PySpark from "./FrontEnd/Components/Content_Table/PySpark_Page";
import JobPage from "./FrontEnd/Components/Job/JobPage";
import CancellationRefundPolicy from "./FrontEnd/Additional/Refund_Page/page";
import MainBlogpage from "./ForumDE_Article/MainPage/Blogpage";
import BlogInfo from "./ForumDE_Article/page/blogInfo/BlogInfo";
import Dashboard from "./ForumDE_Article/page/admin/dashboard/dashboard";
import AdminLogin from "./ForumDE_Article/page/admin/adminLogin/AdminLogin";

import MyState from "./ForumDE_Article/Context/data/myState"; // Import MyState provider
import { Toaster } from "react-hot-toast";
import CreateBlog from "./ForumDE_Article/page/admin/createBlog/createBlog";
import { ProtectedRouteForAdmin } from "./MainComponets/AdminProtect/ProtectedRouteForAdmin";
import CreateSubheading from "./ForumDE_Article/page/admin/createSubheading/subheading";
import Suggest from "./ForumDE_Article/page/blogInfo/Sugest";
import SubheadingShowPage from "./ForumDE_Article/page/Sugestions/ShowSuggestionPage";
import UpdateBlog from "./ForumDE_Article/page/admin/Update/update";

function App() {
  return (
    <>
      <MyState>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Private" element={<PrivacyPolicy />} />
          <Route path="/Terms" element={<TermsAndConditions />} />
          <Route path="/Contact" element={<Contactpage />} />
          <Route path="/UnderWork" element={<Page />} />
          <Route path="/PySpark" element={<PySpark />} />
          <Route path="/Job" element={<JobPage />} />
          <Route path="/Blog" element={<MainBlogpage />} />
          <Route path="/blog" element={<MainBlogpage />} />
          <Route path="/bloginfo/:id" element={<BlogInfo />} />
          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/bloginfo/:parentSlug" element={<Suggest />} />
          <Route path="/bloginfo/:parentSlug/:childSlug" element={<SubheadingShowPage />} />
          <Route
            path="/createblog"
            element={
              <ProtectedRouteForAdmin>
                <CreateBlog />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/create-subheading/:parentSlug"
            element={
              <ProtectedRouteForAdmin>
                <CreateSubheading />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/updateblog/:id"
            element={
              <ProtectedRouteForAdmin>
                <UpdateBlog />
              </ProtectedRouteForAdmin>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRouteForAdmin>
                <Dashboard />
              </ProtectedRouteForAdmin>
            }
          />
          <Route path="/Refund" element={<CancellationRefundPolicy />} />
        </Routes>
        <Toaster />
        <Footer />
      </MyState>
    </>
  );
}

export default App;
