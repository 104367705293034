import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Timestamp, doc, setDoc } from 'firebase/firestore';
import { fireDB } from '../../../Firebase/FirebaseConfigData';
import { Editor } from '@tinymce/tinymce-react'; // Ensure TinyMCE is installed
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

function CreateSubheading() {
  const navigate = useNavigate();
  const { parentSlug } = useParams();
  const [subheadingData, setSubheadingData] = useState({
    title: '',
    content: '',
    slug: '',
    time: Timestamp.now(),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [subheadingCount, setSubheadingCount] = useState(0); // To track the number of subheadings
  const [showPreview, setShowPreview] = useState(false); // State to toggle preview

  const createSubheading = async () => {
    if (!subheadingData.title || !subheadingData.slug || !subheadingData.content) {
      toast.error('Please fill all fields');
      return;
    }

    setIsLoading(true);
    await addSubheading();
  };

  const addSubheading = async () => {
    try {
      const subheadingRef = doc(fireDB, `blogPost/${parentSlug}/subheadings/${subheadingData.slug}`);

      await setDoc(subheadingRef, {
        ...subheadingData,
        time: Timestamp.now(),
        date: new Date().toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
      });

      // Increment the subheading count and show a success notification
      setSubheadingCount(prevCount => prevCount + 1);
      toast.success(`Subheading ${subheadingCount + 1} created successfully!`);

      // Reset form fields for the next subheading
      setSubheadingData({
        title: '',
        content: '',
        slug: '',
        time: Timestamp.now(),
      });

      // Show a message to create the next subheading or finalize the blog
      if (subheadingCount + 1 === 1) {
        toast.success('First subheading created! You can now add more subheadings.');
      } else {
        toast.success(`Subheading ${subheadingCount + 1} created! You can now proceed with the next one.`);
      }

    } catch (error) {
      console.error('Error adding subheading:', error);
      toast.error('Error adding subheading');
    } finally {
      setIsLoading(false);
    }
  };

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const createMarkup = (content: string) => {
    return { __html: content }; // Render the content safely as HTML
  };

  return (
    <div className="bg-gray-50 min-h-screen py-8 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-wrap lg:flex-nowrap gap-4 justify-between">
        {/* Sidebar (optional) */}
        <div className="w-full lg:w-[25%] bg-white p-4 rounded-lg shadow-md">
          <div className="mb-4">
            <h2 className="text-lg font-semibold text-gray-800">Instructions</h2>
            <p className="text-sm text-gray-600">Enter the subheading title, slug, and content. Click submit to create your subheading. You can add multiple subheadings for the same blog.</p>
          </div>
        </div>

        {/* Main Content Area */}
        <div className="w-full lg:w-[70%] bg-white p-6 rounded-lg shadow-md">
          <h1 className="text-2xl sm:text-3xl font-semibold text-gray-800 mb-6">Create Subheading</h1>

          {/* Slug Input */}
          <div className="mb-4">
            <label htmlFor="slug" className="block text-lg font-medium text-gray-700">Enter Your Unique ID (Slug)</label>
            <input
              className="w-full p-3 mt-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Slug"
              name="slug"
              value={subheadingData.slug}
              onChange={(e) => setSubheadingData({ ...subheadingData, slug: e.target.value })}
            />
          </div>

          {/* Title Input */}
          <div className="mb-4">
            <label htmlFor="title" className="block text-lg font-medium text-gray-700">Title</label>
            <input
              className="w-full p-3 mt-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter subheading title"
              value={subheadingData.title}
              onChange={(e) => setSubheadingData({ ...subheadingData, title: e.target.value })}
            />
          </div>

          {/* Editor for Content */}
          <div className="mb-4">
            <label htmlFor="content" className="block text-lg font-medium text-gray-700">Enter Your Content</label>
            <Editor
              apiKey='gcxpuajmpzj70mtimd6kadj8x9mqypbhuptctnqgarsmkk58' 
              value={subheadingData.content}
              onEditorChange={(newValue) => setSubheadingData({ ...subheadingData, content: newValue })}
              init={{
                height: 400,
                menubar: false,
                plugins: 'lists link image preview',
                toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | preview',
              }}
            />
          </div>
           {/* Preview Blog Button */}
           <button
            className="w-full mt-4 p-3 bg-blue-600 text-white text-lg font-semibold rounded-lg hover:bg-blue-700"
            onClick={togglePreview} // Toggle preview
          >
            {showPreview ? 'Hide Preview' : 'Preview Blog'}
          </button>

  {/* Preview Section */}
  {showPreview && (
            <div className="mt-10">
              <h2 className="text-xl font-semibold text-gray-800">Content Preview</h2>
              <div
                className="mt-4 p-4 bg-gray-100 rounded-lg"
                dangerouslySetInnerHTML={createMarkup(subheadingData.content)} // Render the content safely
              />
            </div>
          )}

          <br />
          {/* Submit Button */}
          <div className="flex justify-between gap-4 mt-4">
  <button
    className="w-1/2 p-3 bg-green-600 text-white text-lg font-semibold rounded-lg hover:bg-green-700 disabled:bg-gray-300"
    onClick={createSubheading}
    disabled={isLoading}
  >
    {isLoading ? 'Creating...' : 'Submit Subheading'}
  </button>

  <button
    className="w-1/2 p-3 bg-blue-600 text-white text-lg font-semibold rounded-lg hover:bg-blue-700"
    onClick={() => navigate('/dashboard')}
  >
    Back to Dashboard
  </button>
</div>

        </div>
      </div>

      {/* Toast Container for notifications */}
      <ToastContainer />
    </div>
  );
}

export default CreateSubheading;
